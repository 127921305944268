









































import { Vue, Component, Prop } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { organisationModule } from "@/store/modules/OrganisationModule";
import { Profile } from "@/models/Profile";
import { OrganisationDetails } from "@/models/OrganisationDetails";
import { OrganisationUnit } from "../models/OrganisationUnit";

@Component
export default class OrganisationUnitComponent extends Vue {
  private organisationUnit: any = null;

  private get organisation(): OrganisationDetails {
    return organisationModule.organisation;
  }

  private async update() {

    await adminService.updateOrganisationUnit(this.organisation.id, this.organisationUnit);
 
  }

  private activated() {
    this.organisationUnit = organisationModule.organisation.orgUnits.find(
      (x) => x.id === this.$route.params.id
    );
  }
}
